<template>
  <div>
    <div
      class="py-1 px-2 card mb-2 d-flex align-center w-100"
      :class="error && 'box-error'">
      <div class="d-flex flex-grow-1 justify-space-between align-center">
        <div class="d-flex align-center">
          <Icon
            name="draft"
            size="30px"
            class="ml-3"
            :class="error ? 'icon-error' : 'primary--text'" />
          <div class="d-flex flex-column">
            <span
              class="ml-2 text-capitalize semiBold oneline"
              v-if="!loadReady && document">
              {{
                document?.archivo_descripcion ||
                document?.documento_descripcion ||
                ""
              }}
            </span>
            <div v-else-if="loadReady" class="ml-2 p containerName">
              <span class="elipsis"> {{ document.archivo_descripcion }}</span>
              <span>
                ({{ Math.round(size / 1024) }}
                Kb)
              </span>
            </div>
          </div>
        </div>

        <div v-if="loadReady || document" class="d-flex align-center mr-4">
          <div class="d-flex flex-column ml-8">
            <span class="p">{{ document?.estado_descripcion }}</span>
            <span>{{ Date.now() | formatDate }}</span>
          </div>
        </div>
        <div
          style="min-height: 10px"
          v-else-if="value > 0 && !loadReady"
          class="px-5">
          <span>Subido {{ value }}%</span>
          <v-progress-linear
            v-model="value"
            rounded
            :buffer-value="bufferValue"
            height="10"></v-progress-linear>
          <v-file-input
            outlined
            dense
            class="file_input_id"
            prepend-icon=""
            @change="startProgress"
            accept="application/pdf"
            name="adj_doc_factura" />
        </div>
      </div>
      <div class="d-flex border-left pl-4">
        <v-btn
          v-if="estado === 'REC' || estado === 'PEN'"
          icon
          @click="selectItem(document)">
          <label :for="'file_input_id' + index" class="cursor-pointer">
            <Icon
              name="upload"
              size="25px"
              :class="error ? 'icon-error' : 'icon-info'" />
          </label>

          <v-file-input
            type="file"
            ref="input"
            @change="startProgress"
            class="file_input_id"
            :id="'file_input_id' + index"
            :v-model="file" />
        </v-btn>
        <v-btn
          v-if="document && document?.url_archivo && estado !== 'PEN'"
          icon
          @click="showDocument">
          <Icon
            name="visibility"
            size="20px"
            :class="error ? 'icon-error' : 'icon-info'" />
        </v-btn>
        <v-btn
          v-if="document?.url_archivo && estado !== 'PEN'"
          icon
          @click="cleanDocument">
          <Icon name="delete" size="20px" class="icon-info" />
        </v-btn>
      </div>
    </div>
    <v-layout wrap class="d-flex" justify-center>
      <ModalFile v-if="showFile" :document="document" />
    </v-layout>
  </div>
</template>
<script>
import Icon from "@/components/Icon/Icon.vue";
import { mapActions } from "vuex";
import ModalFile from "@/components/Modal/Modal-show-file.vue";

export default {
  components: {
    Icon,
    ModalFile,
  },
  props: ["document", "index", "error"],
  data() {
    return {
      file: null,
      showFile: false,
      size: 0,
      fileName: "",
      value: 0,
      bufferValue: 100,
      interval: 0,
      loading: false,
      loadReady: false,
    };
  },
  methods: {
    ...mapActions("Payment", ["OpenShowModal"]),
    showDocument() {
      this.showFile = true;
      this.OpenShowModal({ item: this.document });
    },
    selectItem() {
      this.$emit("selectItemEvent", this.document);
    },
    startBuffer() {
      clearInterval(this.interval);

      this.interval = setInterval(() => {
        this.value += 10;
        // this.bufferValue += 10;
      }, 100);
    },
    cleanDocument() {
      this.loadReady = false;
      this.document = null;
      this.value = 0;
    },
    async startProgress(e) {
      if (!e) {
        return false;
      }
      this.cleanDocument();
      this.loading = true;
      this.startBuffer();
      this.size = e.size;
      this.fileName = e.name;
      this.file = await this.toBase64(e);
      // this.value = 100;
      setTimeout(() => {
        this.loadReady = true;
        this.loading = false;
        this.document = {
          archivo_descripcion: e.name,
          fecha_creacion: e.fecha_creacion,
          data_archivo: this.file,
          tipo_archivo: e.type,
        };
      }, 1500);
      // this.file.name = e.name;
    },

    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.toString().split(",")[1]);
        reader.onerror = (error) => reject(error);
      });
    },
  },
  mounted() {
    // this.startBuffer();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {},
  watch: {
    value(val) {
      if (val < 100) return;
      if (val >= 100) {
        clearInterval(this.interval);
        this.$emit("onLoadFile", this.e);
      }
    },
  },
};
</script>

<style scoped>
@import "../../../assets/CSS/ServiceStyle.css";
@import "../../../assets/CSS/Theme.css";

.sub,
.file_input_id {
  cursor: pointer;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
}
.file_input_id {
  display: none;
}

.box-error {
  color: #b3261e !important;
  border-color: #b3261e !important;
}
.box {
  background-color: #e3eeff;
}
.card {
  border-radius: 4px;
  border: 1px solid #cac4d0;
}
.border-left {
  border-left: 1px solid #cac4d0;
}
.oneline {
  text-overflow: ellipsis !important;
  white-space: nowrap;
  overflow: hidden;
  width: 140px;
}
.containerName {
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.elipsis {
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
