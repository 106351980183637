<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="h4 semiBold">Documentos Pendientes </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="light br-8" elevation="0">
          <v-card-text>
            <div class="d-flex align-center justify-space-between g3">
              <div class="filter">
                <LabelComponent text="Tipo de documento" />
                <v-select
                  v-model="selectedTipoDocumento"
                  :items="tipo_documentos"
                  item-text="descripcion"
                  item-value="codigo"
                  outlined
                  dense
                  clearable
                  append-icon="mdi-chevron-down"
                  single-line></v-select>
              </div>
              <div class="filter">
                <LabelComponent text="Ingrese n° documento" />
                <v-text-field
                  class="mx-1"
                  v-model="textSearch.value"
                  outlined
                  style="width: 212px"
                  clearable
                  dense></v-text-field>
              </div>
              <div class="filter">
                <LabelComponent text="Plazo" />
                <v-select
                  v-model="plazoField.value"
                  :items="plazoField.options"
                  item-text="text"
                  item-value="value"
                  :label="plazoField.label"
                  :placeholder="plazoField.placeholder"
                  persistent-hint
                  return-object
                  outlined
                  dense
                  clearable
                  append-icon="mdi-chevron-down"
                  single-line></v-select>
              </div>
              <div class="filter">
                <LabelComponent text="Etapa" />
                <v-select
                  :items="statusField.options"
                  v-model="statusField.value"
                  :label="statusField.label"
                  :placeholder="statusField.placeholder"
                  item-text="text"
                  item-value="value"
                  persistent-hint
                  outlined
                  dense
                  clearable
                  append-icon="mdi-chevron-down"
                  single-line></v-select>
              </div>

              <div class="filter moveY">
                <v-btn
                  color="primary"
                  class="text-none mx-4"
                  width="140"
                  @click="filtrar"
                  rounded>
                  <Icon name="filter_alt" class="text-white" />
                  <span class="ml-2">Filtrar</span>
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="Documentos"
      :loading="loading"
      :server-items-length="totalItems"
      :options.sync="options"
      items-per-page-options="[10, 30]"
      @update:options="setup"
      no-data-text="No hay documentos para mostrar"
      no-results-text="No se han encontrado documentos"
      loading-text="Cargando documentos...">
      <template #item.fecha_creacion="{ item }">
        {{ item.fecha_creacion | formatDate }}
      </template>

      <template #item.rut="{ item }">
        {{
          (item?.numero_documento_cliente ||
            item?.asegurado?.numero_documento ||
            "") | rut
        }}
      </template>

      <template #item.client="{ item }">
        {{ item.nombre_usuario }}
      </template>
      <template #item.seguro="{ item }">
        {{ item.categoria_descripcion }}
      </template>

      <template #item.plazo="{ item }">
        <v-icon
          left
          x-small
          :color="
            statusField.options.find((e) => e.value === item.estado_descripcion)
              ?.color ?? 'grey'
          "
          >mdi-circle</v-icon
        >{{ item.plazo }} días
      </template>

      <template #item.estado="{ item }">
        <v-btn
          small
          rounded
          :color="
            statusField.options.find((e) => e.value === item.estado_descripcion)
              ?.color ?? 'grey'
          ">
          {{ item.estado_descripcion }}</v-btn
        >
      </template>

      <template #item.action="{ item }">
        <v-btn
          color="primary"
          text
          fab
          small
          @click="handlerItemAction(item)"
          :disabled="loading"
          :loading="item.loading">
          <!-- <v-icon>mdi-text-box-search</v-icon> -->
          <v-icon>mdi-eye-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!-- VENTANA DETALLE -->
    <v-dialog v-model="dialog" scrollable persistent max-width="500">
      <v-card :loading="loadingForm">
        <template #progress>
          <v-progress-linear
            :value="charging"
            height="8"
            color="primary"></v-progress-linear>
        </template>

        <v-card-title primary-title>
          <span>N° Propuesta {{ selected?.numero_propuesta ?? "" }}</span>
          <v-spacer></v-spacer>
          <v-chip outlined label
            ><v-icon
              left
              small
              :color="
                statusField.options.find(
                  (e) => e.value === selected?.estado_documentos_codigo
                )?.color ?? 'grey'
              "
              >mdi-circle</v-icon
            >
            {{ selected?.estado_documentos ?? "PENDIENTE" }}</v-chip
          >
        </v-card-title>

        <v-card-text>
          <v-row no-gutters class="my-3">
            <v-col cols="6" md="4">Cliente:</v-col>
            <v-col cols="6" md="8" class="font-weight-bold">
              {{
                `${selected?.asegurado?.nombre ?? ""} ${
                  selected?.asegurado?.primer_apellido ?? ""
                }`
              }}
            </v-col>

            <v-col cols="6" md="4">
              {{ selected?.asegurado?.tipo_documento ?? "RUT" }}:
            </v-col>
            <v-col cols="6" md="8" class="font-weight-bold">
              {{ selected?.asegurado?.numero_documento ?? "--" | rut }}
            </v-col>
          </v-row>
        </v-card-text>

        <!-- DOCUMENTOS PENDIENTES -->
        <v-card-text>
          <v-list subheader two-line dense>
            <v-subheader inset
              >Adjuntar los siguientes documentos para terminar el
              proceso:</v-subheader
            >

            <v-list-item
              class="doc-box"
              v-for="doc in selected?.documentos?.filter((e) =>
                docTypes.includes(e.estado)
              ) ?? []"
              :key="doc.id">
              <v-list-item-content>
                <v-list-item-title
                  v-text="doc.documento_descripcion"></v-list-item-title>

                <v-list-item-subtitle v-if="handlerFindFile(doc.id)">
                  {{ handlerFindFile(doc.id).data.nombre_archivo }}
                  <v-icon right small color="success">mdi-check</v-icon>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-content>
                <v-list-item-title
                  v-text="doc.estado_descripcion"></v-list-item-title>

                <v-list-item-subtitle>{{
                  doc.fecha_creacion | formatDate
                }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <!-- BOTON DE CARGAR DOCUMENTO -->
                <v-btn
                  v-if="!handlerFindFile(doc.id)?.loaded"
                  icon
                  :disabled="disabledUpload(doc)"
                  color="primary"
                  @click="handlerClickUpload(doc.id)"
                  :loading="loadingForm">
                  <v-icon>mdi-upload</v-icon>
                </v-btn>
                <!-- BOTON DE CARGA REALIZADA -->
                <v-btn
                  color="success"
                  icon
                  v-else-if="!handlerFindFile(doc.id)?.error"
                  ><v-icon>mdi-check</v-icon></v-btn
                >
                <!-- BOTON DE ERROR AL CARGAR -->
                <v-btn color="error" icon v-else
                  ><v-icon>mdi-close</v-icon></v-btn
                >
                <input
                  :ref="`field_${doc.id}`"
                  v-show="false"
                  type="file"
                  @change="handlerSelectFile($event, doc)" />
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <p
            v-if="
              (
                selected?.documentos?.filter((e) =>
                  docTypes.includes(e.estado)
                ) ?? []
              ).length == 0
            "
            class="caption text-center mb-0">
            No hay más documentos pendientes.
          </p>
        </v-card-text>

        <!-- DATOS PARA VENTA EN VERDE -->
        <v-card-text v-if="selected?.tipo_venta == 'V'" class="py-3">
          <p class="caption text-center grey--text text--darken-1">
            Agregar datos faltantes a la propuesta:
          </p>
          <v-form ref="verdeForm">
            <v-row no-gutters>
              <v-col cols="12" md="6" class="pr-1">
                <v-text-field
                  v-model="form.numero_motor"
                  dense
                  outlined
                  label="Número de motor"
                  :onkeypress="`return ${alphaNum()}`"
                  :rules="[
                    (v) => !!v || 'El número de motor es requerido.',
                    (v) => formRules.min(v, 8),
                  ]"
                  @keyup="form.numero_motor = form.numero_motor.toUpperCase()"
                  maxlength="20"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pl-1">
                <v-text-field
                  v-model="form.numero_chasis"
                  dense
                  outlined
                  label="Número de chasis"
                  :onkeypress="`return ${alphaNum()}`"
                  :rules="[
                    (v) => !!v || 'El número de chasis es requerido.',
                    (v) => formRules.min(v, 17),
                  ]"
                  @keyup="form.numero_chasis = form.numero_chasis.toUpperCase()"
                  maxlength="20"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pr-1">
                <v-text-field
                  v-model="form.numero_factura"
                  dense
                  outlined
                  label="Número de factura"
                  :rules="[
                    (v) => !!v || 'El número de factura es requerido.',
                  ]"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pl-1">
                <v-menu
                  ref="menu1"
                  v-model="dateMenu"
                  close-on-content-click
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  name="RequirementsDate">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.fecha_factura"
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      label="Fecha de factura"
                      :rules="[
                        (v) => !!v || 'El campo fecha factura es requerido.',
                      ]"></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.fecha_factura"
                    min="1950-01-01"
                    name="picker_fecha_factura"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <!-- DOCUMENTOS SUBIDOS -->
        <v-card-text
          v-if="
            (
              selected?.documentos?.filter(
                (e) => !docTypes.includes(e.estado)
              ) ?? []
            ).length > 0
          ">
          <v-list subheader two-line dense>
            <v-subheader inset>Documentos adjuntos:</v-subheader>

            <v-list-item
              class="doc-box"
              v-for="(doc, i) in selected?.documentos?.filter(
                (e) => !docTypes.includes(e.estado)
              ) ?? []"
              :key="`doc_uploaded_${i}`">
              <v-list-item-content>
                <v-list-item-title
                  v-text="doc.documento_descripcion"></v-list-item-title>

                <v-list-item-subtitle
                  v-text="doc.archivo_descripcion"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-content>
                <v-list-item-title
                  v-text="doc.estado_descripcion"></v-list-item-title>

                <v-list-item-subtitle>{{
                  doc.fecha_creacion | formatDate
                }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  icon
                  @click="downloadFile(doc)"
                  color="primary"
                  :loading="doc.loading">
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </v-list-item-action>

              <!-- <v-list-item-action>
                <v-btn icon disabled color="error">
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </v-list-item-action> -->
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions class="justify-center pb-4">
          <v-btn
            class="text-none"
            width="150"
            color="primary"
            :disabled="loadingForm"
            rounded
            outlined
            @click="handlerCloseDialog()"
            >Cerrar</v-btn
          >
          <v-btn
            class="text-none"
            width="150"
            color="primary"
            :loading="loadingForm"
            :disabled="selectedDocs.length === 0"
            rounded
            @click="uploadFile()"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="resultDialog" scrollable persistent max-width="350px">
      <v-card>
        <v-card-title primary-title> Resultado </v-card-title>
        <v-card-text>
          <v-list>
            <v-subheader inset>Carga de documentos:</v-subheader>
            <v-list-item
              class="doc-box"
              v-for="(r, i) in chargingResult"
              :key="`doc_result_${i}`">
              <v-list-item-content>
                <v-list-item-title v-text="r.doc"></v-list-item-title>

                <p class="mb-0">{{ r.info }}</p>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon v-if="r.status === 'error'" color="error"
                  >mdi-close</v-icon
                >
                <v-icon v-if="r.status === 'success'" color="success"
                  >mdi-check</v-icon
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="handlerCloseResult()">Entendido</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ModalDetail
      v-if="openModalDetail"
      :document="selectedDocument"
      :close="toggleModalDetail" />
    <!-- <DocumentRejected
      v-if="openRejectedModal"
      :document="selectedDocument"
      :data="itemData"
      :close="toggleRejected" />

    <DocumentRevision
      v-if="openRevisionModal"
      :document="selectedDocument"
      :data="itemData"
      :close="toggleRevision" /> -->
    <select-modal
      ref="selectModal"
      :options="['FACTURA', 'ACTA', 'GUIA']"
      :title="'Seleccione el tipo de documento'" />
  </v-container>
</template>
<script>
import ModalDetail from "@/components/Modal/Modal-document";
// import DocumentRejected from "@/components/Modal/Modal-document-rejected";
// import DocumentRevision from "@/components/Modal/Modal-document-revision";
import { mapActions, mapGetters } from "vuex";
import { PaginationMixin } from "@/Mixins/PaginationMixin.js";
import { fileToBase64 } from "@/services/file.js";
import { validarMinNumLimit } from "@/store/resources/validate.js";
import { alphaNumericKeys } from "@/store/resources/calc.js";
// import TextField from "@/components/Inputs/TextField";
import LabelComponent from "@/components/Inputs/Label.vue";
import Icon from "@/components/Icon/Icon.vue";
// Mixins
import DocumentMixin from "@/Mixins/DocumentsMixin.js";

import SelectModal from "@/components/Modal/SelectModal.vue";

export default {
  name: "DocumentsPage",
  mixins: [PaginationMixin, DocumentMixin],
  components: {
    SelectModal,
    LabelComponent,
    Icon,
    ModalDetail,
    // PendingModal,
    // DocumentRejected,
    // DocumentRevision,
  },
  data() {
    return {
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      itemsPerPageOptions: [5, 10, 15, 20],
      textsearch: "",
      loading: false,
      selected: null,
      selectedDocs: [],
      dialog: false,
      resultDialog: false,
      loadingForm: false,
      selectedTipoDocumento: null,
      tipo_documentos: JSON.parse(sessionStorage.getItem("tipo_documentos")),
      headers: [
        {
          text: "Nº Póliza",
          value: "numero_propuesta",
          align: "left",
          width: "13%",
          sortable: false,
        },
        {
          text: "Tipo Documento",
          value: "tipo_documento",
          align: "left",
          sortable: false,
        },
        {
          text: "N° documento",
          value: "numero_documento",
          align: "left",
          sortable: false,
        },
        {
          text: "Cliente",
          width: "13%",
          value: "client",
          align: "left",
          sortable: false,
        },
        {
          text: "Seguro",
          value: "seguro",
          align: "left",
          sortable: false,
        },
        {
          text: "Tipo de venta",
          value: "venta_descripcion",
          align: "left",
          sortable: false,
        },
        {
          text: "Fecha vencimiento",
          value: "plazo",
          align: "center",
          sortable: false,
        },
        {
          text: "Estado",
          value: "estado",
          align: "center",
          sortable: false,
        },
        {
          text: "Acción",
          value: "action",
          align: "left",
          sortable: false,
        },
      ],

      plazoField: {
        label: "Plazo",
        placeholder: "Seleccione un plazo de retraso",
        options: [
          {
            text: "1 a 15 Días",
            value: "1-15",
          },
          {
            text: "16 a 30 Días",
            value: "16-30",
          },
          {
            text: "31 a 60 Días",
            value: "31-60",
          },
          {
            text: "60 Días o más",
            value: "61-",
          },
        ],
        value: "",
      },
      statusField: {
        label: "Estado",
        placeholder: "Seleccione uno o más estado de los documentos",
        options: [
          {
            text: "Adjuntado",
            value: "ADJUNTADO",
            color: "purple",
          },
          {
            text: "Generado",
            value: "GENERADO",
            color: "primary",
          },
          {
            text: "Pendiente",
            value: "PENDIENTE",
            color: "warning",
          },
          {
            text: "Rechazado",
            value: "RECHAZADO",
            color: "error",
          },
          {
            text: "Revisión",
            value: "REVISION",
            color: "info",
          },
          {
            text: "Aprobado",
            value: "APROBADO",
            color: "success",
          },
        ],
        value: null,
      },
      textSearch: {
        placeholder: "Ingrese un nro de propuesta o RUT...",
        label: "N° de propuesta o RUT",
        value: "",
        icon: "mdi-magnify",
      },
      docTypes: ["REC", "PEN"],
      charging: 0,
      chargingResult: [],
      form: {
        numero_chasis: "",
        numero_motor: "",
        numero_factura: "",
        fecha_factura: "",
      },
      formRules: {
        min: validarMinNumLimit,
      },
      dateMenu: false,
      openModalDetail: false,
      // openRejectedModal: false,
      // openPendingModal: false,
      // openRevisionModal: false,
      selectedDocument: null,
      itemData: null,
    };
  },

  watch: {
    async country(val) {
      if (val) {
        this.tipo_documentos = await this.getTiposDocumentos(this.country);
      }
    },
    options: {
      handler() {
        this.setup();
      },
      deep: true,
    },
  },
  mounted() {
    this.setQueryConditions(this.$route.query);
    this.setup();
  },
  computed: {
    ...mapGetters("Users", ["getUserInfo"]),
    ...mapGetters("Documents", ["Documentos"]),
    country() {
      return this.$store.getters.getCountry;
    },
    alphaNum: () => alphaNumericKeys,
  },
  methods: {
    ...mapActions("Documents", ["GetDocs", "UploadDoc", "GetDocsByNumber"]),
    ...mapActions("Internationalization", ["getTiposDocumentos"]),

    toggleModalDetail() {
      this.openModalDetail = !this.openModalDetail;
    },

    setQueryConditions(query) {
      const propuesta = query?.propuesta;
      if (propuesta) {
        this.handlerItemAction({ numero_propuesta: propuesta });
      }
    },

    filtrar() {
      this.pagination.page = 1;
      this.setup();
    },

    setup() {
      this.loading = true;
      let params = {
        limite: this.options.itemsPerPage,
        pagina: this.options.page,
      };
      // PARAMETROS CONDICIONALES
      // * Por rut del cliente
      if (this.$route.query.rut) {
        params.cliente = this.$route.query.rut;
      } else {
        // Por usuario logeado
        params.usuario = true;
      }
      // Por el campo de buscar rut o propuesta
      if (this.textSearch.value) {
        params.numero_documento = this.textSearch.value;
      }
      // Por el campo de plazo
      if (this.plazoField.value) {
        params.retraso = this.plazoField.value.value;
      }
      // Por el campo de estado de los documentos
      if (this.statusField.value) {
        params.estado = this.statusField.value;
      }

      if (this.selectedTipoDocumento) {
        params.tipo_documento = this.selectedTipoDocumento;
      }
      this.GetDocs(params)
        .then((response) => {
          const pagination = response.pagination;
          this.totalItems = response.paginacion.registros;
          this.setOrResetPagination(pagination);
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getItems(params = {}) {
      return new Promise((resolve, reject) => {
        this.GetDocs(params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    async getItemByProposal(number) {
      const params = {
        numero_propuesta: number,
      };
      const { result } = await this.getItems(params);
      if (result?.length > 0) {
        return result[0];
      } else {
        this.$store.commit("setSnackbar", {
          active: true,
          text: "No se ha encontrado la propuesta seleccionada.",
          top: true,
          right: true,
          color: "warning",
        });
        return false;
      }
    },

    async handlerItemAction(item) {
      item.loading = true;
      this.selectedDocument = item;
      this.openModalDetail = true;
      item.loading = false;
    },

    handlerCloseDialog() {
      this.selected = null;
      this.dialog = false;
      this.chargingResult = [];
      // Reset Form
      this.form.numero_chasis = "";
      this.form.numero_factura = "";
      this.form.numero_motor = "";
      this.form.fecha_factura = "";

      this.setup();
    },

    handlerClickUpload(ref) {
      const input = this.$refs[`field_${ref}`]?.[0];
      input?.click();
    },

    handlerFindFile(ref) {
      return this.selectedDocs.find((e) => e.ref == ref);
    },

    async handlerSelectFile(input, item) {
      const file = input.target.files[0];
      const base64 = await fileToBase64(file);
      const fileData = base64.split(";base64,")?.[1] ?? "";
      let docDesc = item.documento_descripcion;
      if (item.documento === "ENT") {
        docDesc = await this.$refs.selectModal.open();
      }
      const formData = {
        ref: item.id,
        loaded: false,
        error: false,
        data: {
          numero_propuesta: this.selected?.numero_propuesta ?? "",
          tipo_documento: item.documento,
          documento_descripcion: docDesc,
          nombre_archivo: file.name,
          tipo_archivo: file.type,
          documento: fileData,
        },
      };
      const ckeckFile = this.selectedDocs.find((e) => e.ref === item.id);
      if (ckeckFile) {
        ckeckFile.data = formData.data;
      } else {
        this.selectedDocs.push(formData);
      }
    },

    async uploadFile() {
      const isVerde = this.selected?.tipo_venta === "V";
      let formValid = isVerde ? this.$refs.verdeForm.validate() : true;
      const resultList = [];
      this.charging = 0;
      if (formValid) {
        this.loadingForm = true;
        for (let i in this.selectedDocs) {
          let data = {
            info: "",
            doc: this.selectedDocs[i].data.nombre_archivo,
            status: null,
          };
          let extraData = {};
          if (isVerde) {
            extraData = this.form;
          }
          await this.UploadDoc({ ...this.selectedDocs[i].data, ...extraData })
            .then((response) => {
              data.info = response.info;
              data.status = "success";
            })
            .catch((e) => {
              data.info = e;
              data.status = "error";
              this.selectedDocs[i].error = true;
            })
            .finally(() => {
              resultList.push(data);
              this.selectedDocs[i].loaded = true;
              this.charging += 100 / this.selectedDocs.length;
            });
        }
        this.chargingResult = resultList;
        await setTimeout(() => {
          this.loadingForm = false;
          this.resultDialog = true;
        }, 1500);
      }
    },

    handlerCloseResult() {
      this.handlerItemAction(this.selected);
      this.resultDialog = false;
      this.selectedDocs = [];
    },
  },
};
</script>

<style scoped>
.doc-box {
  margin: 6px 0 6px 0;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}
</style>

<style scoped src="../../assets/CSS/SimpleTable.css"></style>
<style scoped>
.filter {
  max-width: 212px;
}
</style>
