<template>
  <div class="text-center">
    <v-dialog persistent v-model="open" width="525px" attach="#main-container">
      <v-card outlined class="light">
        <div class="d-flex">
          <div class="d-flex flex-grow-1 pl-4 pt-2 pb-4">
            <v-container>
              <v-row>
                <v-col>
                  <div class="d-flex justify-space-between">
                    <div
                      class="d-flex justify-space-between align-center mt-2 py-2 px-2 box flex-grow-1">
                      <span class="h6 semiBold"
                        >N° Propuesta {{ document.numero_propuesta }}</span
                      >
                      <v-chip
                        v-if="document.estado === 'ER'"
                        color="#223A65"
                        class="px-8">
                        <span class="p text-white">En revisión</span>
                      </v-chip>
                      <v-chip
                        v-if="document.estado === 'REC'"
                        color="#F25C53"
                        class="px-8">
                        <span class="p text-white">Rechazado</span>
                      </v-chip>
                      <v-chip
                        v-if="document.estado === 'PEN'"
                        color="#FAB76A"
                        class="px-8">
                        <span class="p">Pendiente</span>
                      </v-chip>
                    </div>
                    <!-- <v-btn class="mt-2 ml-2" icon @click="closebtn()">
                            <v-icon class="global-icon6"> mdi-close</v-icon>
                          </v-btn> -->
                  </div>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <div class="d-flex">
                    <div class="w-20">
                      <span class="p">Cliente</span>
                    </div>
                    <div class="w-80">
                      <span class="semiBold">
                        {{ document.nombre_usuario }}
                      </span>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <div class="d-flex">
                    <div class="w-20">
                      <span class="p">{{ document.tipo_documento }}</span>
                    </div>
                    <div class="w-50">
                      <span class="semiBold">
                        {{ document.numero_documento }}
                      </span>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <Alert variant="info" class="w-100 mt-2">
                    <template slot="content">
                      <div>
                        <span class="p"
                          >La documentación adjunta se encuentra en proceso de
                          revisión</span
                        >
                      </div>
                    </template>
                  </Alert>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12">
                  <DocumentComponent
                    :document="document"
                    :index="1"
                    @selectItemEvent="handleSelectItem"
                    @onLoadFile="onPropostaSelected" />
                  <!-- <div
                    v-for="(item, i) in filteredDocTypes"
                    :key="i"
                    class="py-2 px-2 w-100 card mb-2 d-flex align-center justify-space-between">
                    <div class="d-flex align-center">
                      <Icon
                        name="draft"
                        size="25px"
                        class="ml-3 primary--text" />
                      <span class="ml-2 text-capitalize semiBold">
                        {{ item.archivo_descripcion }}
                      </span>
                    </div>
                    <div class="d-flex flex-column ml-8">
                      <span class="p">Subido</span>
                      <span>{{ item.fecha }}</span>
                    </div>
                    <div class="border-left pl-4">
                      <v-btn
                        icon
                        @click="
                          {
                          }
                        ">
                        <Icon name="visibility" size="20px" class="icon-info" />
                      </v-btn>
                    </div>
                  </div> -->
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center">
                  <v-btn
                    color="primary"
                    rounded
                    :loading="loadingstatus"
                    @click="close()"
                    width="140"
                    class="text-none">
                    Entendido
                  </v-btn>
                </v-col>
              </v-row>
              <!-- </div> -->
            </v-container>
          </div>
          <div class="mt-4 pr-1">
            <v-btn icon @click="close()">
              <Icon name="close" />
            </v-btn>
          </div>
        </div>

        <span class="red--text">{{ error }}</span>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Icon from "@/components/Icon/Icon.vue";
import Alert from "@/components/Alert/Alert.vue";
import DocumentComponent from "@/components/Pages-Components/Documentos/documento.vue";
// import LabelComponent from "@/components/Inputs/Label.vue";
// import InputField from "@/components/Inputs/Input.vue";
import moment from "moment-timezone";
import axios from "axios";

export default {
  components: {
    Alert,
    Icon,
    DocumentComponent,
    // LabelComponent,
    // InputField,
  },
  props: ["document", "close", "estado"],
  data() {
    return {
      error: "",
      open: true,
      adjuntarDocs: [],
      type: "",
      newDocuments: "",
      disabled: true,
      file: null,
    };
  },
  mounted() {
    this.adjuntarDocs = this.items;
  },

  methods: {
    b64toBlob(b64Data, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    toBase64(file) {
      new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          var fileData = reader.result.toString().split(",");

          this.file = fileData[1];

          this.$emit("this.file", reader.result);
          resolve(this.file);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    },
    async downDownloadbtn(item) {
      if (item.url_archivo) {
        axios({
          url: item.url_archivo,
          method: "GET",
        }).then((res) => {
          const link = document.createElement("a");
          link.href = res.data.data.url;
          link.target = "_blank";
          link.click();
        });
      } else {
        if (item.data_archivo !== null) {
          const blob = this.b64toBlob(item.data_archivo, item.tipo_archivo);

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = item.archivo_descripcion;
          link.click();
        }
      }
    },

    async onPropostaSelected(event) {
      this.disabled = false;

      const Name = event.target.files[0].name;
      const Type = this.type;
      const Size = event.target.files[0].size;
      const Fecha = new Date();

      await this.toBase64(event.target.files[0]);

      const NewData = [
        {
          archivo_descripcion: Name,
          documento_descripcion: Type.tipo_documento_descripcion,
          size_archivo: Size,
          fecha_creacion: Fecha,
          PropostaNumber: this.PropuestaData.numero_propuesta,
          archivo: event.target.files[0],
          File_Type: event.target.files[0].type,
        },
      ];

      this.newDocuments = NewData[0];

      this.PropuestaData.documentos.push(this.newDocuments);
    },

    postData() {
      this.$store.commit("loadingstatus", true);
      axios({
        url: "tareas/documentos",
        method: "POST",
        data: {
          propuesta: this.newDocuments.PropostaNumber,
          tipo_documento: this.type.tipo_documento,
          archivo: this.file,
          nombre_documento: this.newDocuments.archivo_descripcion,
          extension: this.newDocuments.File_Type,
        },
      })
        .then((response) => {
          if (response) {
            this.$store.commit("loadingstatus", false),
              this.$store.commit("usermodal", false);
            this.$store.commit("POST_SUCCESS");
          }

          const blob = this.b64toBlob(this.file, this.newDocuments.File_Type);

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "probando";
          link.click();
        })
        .catch((err) => {
          this.error = err.response.data.data.lista_mensaje[0].mensaje;

          this.$store.commit("loadingstatus", false);
        })
        .finally(() => {
          const message =
            "Gracias por subir la documentación, ésta será procesada a la brevedad por el departamento de operaciones";
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "success",
          });
        });
    },

    handleSelectItem(item) {
      this.type = item;
    },

    formatedEpoch(epoch) {
      return moment.unix(epoch / 1000).format("DD/MM/YYYY");
    },
  },

  computed: {
    DocsData() {
      return this.$store.getters.NumProposta;
    },

    loadingstatus() {
      return this.$store.getters.loadingstatus;
    },
    // filteredDocTypes() {
    //   return this.data.documentosListos;
    // },
    DocTypes() {
      return this.$store.getters.DocTypes;
    },
  },
};
</script>

<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
@import "../../assets/CSS/Theme.css";

/* DROPDOWN BUTTON */
.sub,
#file_input_id {
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
}
#file_input_id {
  display: none;
}

.box {
  background-color: #e3eeff;
}
.card {
  border-radius: 4px;
  border: 1px solid #cac4d0;
}
.card-error {
  border: 1px solid #b3261e;
}
.border-left {
  border-left: 1px solid #cac4d0;
}
</style>
